import React from 'react'
import {Redirect} from "react-router-dom";
import RouteEnum from "../../constants/RouteEnum";
import {connect} from "react-redux";
import AppAction from "../../stores/app/AppAction";
import {Formik, Field} from 'formik';
import CreateOrUpdateRental from "../../utilities/dispatchers/CreateUpdateRental";
import Translate from "../../utilities/etc/Translate";
import ApiEnum from "../../constants/ApiEnum";
import AppAxios from "../../utilities/etc/AppAxios";

const mapStateToProps = (state, ownProps) => ({
    ...state.app,
    cart: state.shoppingCart,
    insurance: state.insurance,
    rental: state.rental,
    languages: state.generalApi.languageData.list,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation,
    commentsEnabled: state.info.infoData.detail.webshops?.partner?.client_id === 14 ?? false,
    participantsEnabled: state.info.infoData.detail.webshops?.webshop.show_participants_field
});

const setLocationDetailsByZipcode = (zipcode, houseNumber, setFieldValue) => {

    AppAxios.get(ApiEnum.ApiClientAddress + '/find-by-zipcode?zipCode=' + zipcode + '&houseNumber=' + houseNumber, {
        headers: {
            'Authorization': `Bearer ` + localStorage.getItem('accessToken')
        }
    }).then((response) => {
        if (response?.data?.items?.address) {
            setFieldValue('address.street', response?.data?.items?.address.street)
            setFieldValue('address.city', response?.data?.items?.address.city)

        }

    })

}

class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMore: false
        }
    }


    componentDidMount() {
        this.props.scroll()

        const hideDropOffDeliveryOptions = this.props.webshop.hide_drop_off_delivery_options ?? false;
        const partnerLocationId = hideDropOffDeliveryOptions ? this.props.webshop.partner_id : this.props.delivery.partnerLocationId;

        if (partnerLocationId) {

            this.props.dispatch(AppAction.switchStep(3))
            this.props.dispatch(AppAction.setSummary({
                period: true,
                bicycles: true,
                accessoires: true,
                accommodation: true,
                delivery: true
            }))

            //SetAndValidateItems(this.props)

            CreateOrUpdateRental(this.props)
        }


    }

    submitValues (values){
        const enableMinimumNaw = this.props.webshop.enable_minimum_naw ?? false;
        values.type = enableMinimumNaw ? 'private' : values.type
        this.props.dispatch(AppAction.setFormData(values)).then(() => {
            this.props.dispatch(AppAction.switchAppStep(RouteEnum.PaymentOverview))
        })
    }


    render() {

        const props = this.props;
        const hideDropOffDeliveryOptions = this.props.webshop.hide_drop_off_delivery_options ?? false;
        const partnerLocation = Object.values(this.props.locations)[0];
        const partnerLocationId = hideDropOffDeliveryOptions === false ? partnerLocation.id : this.props.delivery.partnerLocationId;
        const enableMinimumNaw = this.props.webshop.enable_minimum_naw ?? false;


        if (!partnerLocationId) {
            return null
        }

        let languages = []
        if (this.props.languages.countries) {
            languages = Object.values(this.props.languages.countries);
        }

        return (
            <div className="o-container o-container--primary">
                <Formik
                    key={'form-view'}
                    initialValues={this.props.form.data}
                    onSubmit={(values) => {
                        this.submitValues(values)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <header className="c-subject__header">

                                {!this.props.webshop.recurring_enabled &&
                                    <h2>{Translate('Vul a.u.b. uw gegevens in', props.language)}</h2>
                                }

                                {!!this.props.webshop.recurring_enabled &&
                                    <h2>{Translate('Gegevens berijder invullen', props.language)}</h2>
                                }

                            </header>
                            <div className="c-customer-info">
                                {!enableMinimumNaw && this.props.webshop.type.code !== 'registration' &&
                                    <div className="c-form__row">
                                        <div className="c-form__group c-form__group--radio">
                                            <label htmlFor="private">
                                                <Field
                                                    type="radio" id="private"
                                                    name={'type'}
                                                    value={'private'}
                                                    required
                                                />
                                                <span> {Translate('Particulier', props.language)} </span>
                                            </label>

                                            <label htmlFor="company">
                                                <Field
                                                    type="radio"
                                                    id="company"
                                                    name={'type'}
                                                    value={'company'}
                                                    required
                                                />
                                                <span> {Translate('Bedrijf', props.language)} </span>
                                            </label>

                                        </div>
                                    </div>
                                }

                                {(enableMinimumNaw || values.type === 'private' || this.props.webshop.type.code === 'registration') &&
                                    <>
                                        <div className="c-form__row c-form__row--quarter">
                                            <div className="c-form__group c-form__group--extra-small">
                                                <label htmlFor="">{Translate('Aanhef', props.language)}</label>
                                                <div className="c-form__select">
                                                    <i className="icon icon-arrow-down"/>
                                                    <Field as="select" name="privatePerson.gender_id">
                                                        <option value={false}
                                                                disabled={true}>{Translate('Aanhef', props.language)}
                                                        </option>
                                                        <option
                                                            value={1}>{Translate('Meneer', props.language)}
                                                        </option>
                                                        <option
                                                            value={2}>{Translate('Mevrouw', props.language)}
                                                        </option>
                                                        <option
                                                            value={3}>{Translate('Persoon', props.language)}
                                                        </option>
                                                    </Field>
                                                </div>
                                            </div>

                                            {this.props.webshop.type.code !== 'registration' &&
                                                <div className="c-form__group c-form__group--extra-small">
                                                    <label
                                                        htmlFor="">{Translate('Voorletters', props.language)}</label>
                                                    <Field
                                                        name={'privatePerson.initials'}
                                                        type="text"
                                                    />
                                                </div>
                                            }

                                            <div className="c-form__group">
                                                <label htmlFor="">{Translate('Voornaam', props.language)}*</label>
                                                <Field
                                                    required
                                                    name={'privatePerson.first_name'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="c-form__group c-form__group--extra-small">
                                                <label
                                                    htmlFor="">{Translate('Tussenvoegsel', props.language)}</label>
                                                <Field
                                                    name={'privatePerson.insertion'}
                                                    type="text"
                                                />
                                            </div>

                                            <div className="c-form__group">
                                                <label htmlFor="">{Translate('Achternaam', props.language)}*</label>
                                                <Field
                                                    name={'privatePerson.last_name'}
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="c-form__row">
                                            {!!this.props.webshop.recurring_enabled &&
                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('Geboortedatum', props.language)}*</label>
                                                    <Field
                                                        name={'privatePerson.birth_date'}
                                                        type="date"
                                                        required
                                                    />
                                                </div>
                                            }
                                            <div className="c-form__group">
                                                <label
                                                    htmlFor="">{Translate('E-mailadres', props.language)}*</label>
                                                <Field
                                                    name={'privatePerson.email'}
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <div className="c-form__group">
                                                <label
                                                    htmlFor="">{Translate('Tel.nummer', props.language)}*</label>
                                                <Field
                                                    name={'privatePerson.telephone'}
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        {!!this.props.participantsEnabled && !this.props.commentsEnabled &&
                                            <>
                                                <div className="c-form__row">
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{Translate('Deelnemers Naam / Leeftijd', props.language)}*</label>
                                                        <Field as={'textarea'} name={'remarks'} id="remarks" cols="20"
                                                               rows="5" required/>
                                                    </div>
                                                </div>

                                                <div className="c-form__row">
                                                    <div className={'c-form-notice'}
                                                         style={{
                                                             padding: '1rem 1.5rem',
                                                             background: 'yellow',
                                                             position: 'relative'
                                                         }}>
                                                        <p>{Translate('Let op: Voer hier alleen de namen en leeftijden van de deelnemers: John Doe: 35, Jane Doe: 34, Little Joe: 5', props.language)}</p>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {this.props.commentsEnabled && !this.props.participantsEnabled &&
                                            <>
                                                <div className="c-form__row">
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{Translate('Opmerkingen / notities', props.language)}</label>
                                                        <Field as={'textarea'} name={'remarks'} id="" cols="30"
                                                               rows="10"/>
                                                    </div>
                                                </div>


                                                <div className="c-form__row">
                                                    <div className={'c-form-notice'} style={{
                                                        padding: '1rem 1.5rem',
                                                        background: 'yellow',
                                                        position: 'relative'
                                                    }}>
                                                        <p>{Translate('Let op: Het hier tekstueel aangeven van een andere type *item_single* of maat zal door ons niet in behandeling worden genomen.', props.language)}</p>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {!enableMinimumNaw && this.props.webshop.type.code !== 'registration' &&
                                            <>
                                                <div className="c-form__row">

                                                    <div className="c-form__group">
                                                        <label htmlFor="">Land</label>
                                                        <div className="c-form__select">
                                                            <i className="icon icon-arrow-down"/>
                                                            <Field as="select" name="address.country_id">
                                                                <option value={false}
                                                                        disabled={true}>{Translate('Land', props.language)}
                                                                </option>
                                                                {languages.map((language) => (
                                                                    <option value={language.id}
                                                                            key={'personal-language-' + language.id}>{language.name}</option>
                                                                ))}
                                                            </Field>
                                                        </div>
                                                    </div>

                                                    <div className="c-form__group c-form__group--small">
                                                        <label
                                                            htmlFor="">{Translate('Postcode', props.language)}*</label>
                                                        <Field
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(' ', '')

                                                                setFieldValue('address.zip_code', e.target.value)

                                                                if (value.length === 6 && values.address.house_number) {
                                                                    setLocationDetailsByZipcode(value, values.address.house_number, setFieldValue)
                                                                }

                                                            }}
                                                            name={'address.zip_code'}
                                                            type="text"
                                                            required
                                                        />
                                                    </div>

                                                    <div className="c-form__group c-form__group--small">
                                                        <label
                                                            htmlFor="">{Translate('Huisnr.', props.language)}*</label>
                                                        <Field
                                                            name={'address.house_number'}
                                                            type="number"
                                                            required
                                                            onChange={(e) => {
                                                                const {value} = e.target

                                                                setFieldValue('address.house_number', value)

                                                                if (values.address.zip_code.length === 6 && value.length) {
                                                                    setLocationDetailsByZipcode(values.address.zip_code, value, setFieldValue)
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="c-form__group c-form__group--small">

                                                        <label
                                                            htmlFor="">{Translate('Toevoeging', props.language)}</label>

                                                        <Field
                                                            name={'address.house_number_addition'}
                                                            type="text"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="c-form__row">

                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{Translate('Straat', props.language)}*</label>
                                                        <Field
                                                            name={'address.street'}
                                                            type="text"
                                                            required
                                                        />
                                                    </div>

                                                    <div className="c-form__group">

                                                        <label
                                                            htmlFor="">{Translate('Plaats', props.language)}*</label>
                                                        <Field
                                                            name={'address.city'}
                                                            type="text"
                                                            required
                                                        />

                                                    </div>

                                                </div>
                                            </>
                                        }

                                        {!!this.props.webshop.identification_required &&
                                            <>
                                                <div className={'c-form__row'}>
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{Translate('Documentnummer', props.language)}*</label>

                                                        <Field
                                                            required
                                                            name={'document_number'}
                                                            type="text"
                                                        />


                                                    </div>
                                                    <div className={"c-form__group"}>
                                                        <label
                                                            htmlFor="">{Translate('Document type', props.language)}*</label>
                                                        <div className={'c-form__select'}>
                                                            <i className="icon icon-arrow-down"/>
                                                            <Field as="select" name="document_type" required>
                                                                {this.props.webshop.client_id !== 15 && (
                                                                    <>
                                                                        <option value={'passport'}>{Translate('Paspoort', this.props.language)}</option>
                                                                        <option value={'identity-card'}>{Translate('Identiteitskaart', this.props.language)}</option>
                                                                    </>
                                                                )}
                                                                <option
                                                                    value={'drivers-license'}>{Translate('Rijbewijs', props.language)}
                                                                </option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }

                                {values.type === 'company' &&
                                    <>

                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label
                                                    htmlFor="">{Translate('Bedrijfsnaam', props.language)}*</label>
                                                <Field
                                                    name={'company.name'}
                                                    type="text"
                                                    required
                                                />
                                            </div>

                                            <div className="c-form__group">
                                                <label htmlFor="">{Translate('Voorletters', props.language)}</label>
                                                <Field
                                                    name={'contactPerson.initials'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="">{Translate('Voornaam', props.language)}*</label>
                                                <Field
                                                    name={'contactPerson.first_name'}
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                            <div className="c-form__group">
                                                <label
                                                    htmlFor="">{Translate('Tussenvoegsel', props.language)}</label>
                                                <Field
                                                    name={'contactPerson.insertion'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="">{Translate('Achternaam', props.language)}*</label>
                                                <Field
                                                    name={'contactPerson.last_name'}
                                                    type="text"
                                                    required
                                                />
                                            </div>


                                        </div>

                                        <div className="c-form__row">

                                            <div className="c-form__group">
                                                <label
                                                    htmlFor="">{Translate('E-mailadres', props.language)}*</label>
                                                <Field
                                                    name={'company.primary_email'}
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <div className="c-form__group">
                                                <label
                                                    htmlFor="">{Translate('Tel.nummer', props.language)}*</label>
                                                <Field
                                                    name={'contactPerson.phone'}
                                                    type="text"
                                                    required
                                                />
                                            </div>

                                        </div>

                                        {!!this.props.participantsEnabled &&
                                            <>
                                                <div className="c-form__row">
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{Translate('Naam / Leeftijd', props.language)}*</label>
                                                        <Field as={'textarea'} name={'remarks'} id="remarks" cols="20"
                                                               rows="5" required/>
                                                    </div>
                                                </div>

                                                <div className="c-form__row">
                                                    <div className={'c-form-notice'}
                                                         style={{
                                                             padding: '1rem 1.5rem',
                                                             background: 'yellow',
                                                             position: 'relative'
                                                         }}>
                                                        <p>{Translate('Let op: Voer hier alleen de namen en leeftijden van de deelnemers: John Doe: 35, Jane Doe: 34, Little Joe: 5', props.language)}</p>
                                                    </div>
                                                </div>
                                            </>
                                        }


                                        {/**
                                         <div className="c-form__row">
                                         <div className="c-form__group">
                                         <label
                                         htmlFor="">{Translate('Opmerkingen / notities', props.language)}</label>
                                         <Field as={'textarea'} name={'remarks'} id="" cols="30"
                                         rows="10"/>
                                         </div>
                                         </div>

                                         <div className="c-form__row">
                                         <div className={'c-form-notice'} style={{
                                         padding: '1rem 1.5rem',
                                         background: 'yellow',
                                         position: 'relative'
                                         }}>
                                         <p>{Translate('Let op: Het hier tekstueel aangeven van een andere type *item_single* of maat zal door ons niet in behandeling worden genomen.', props.language)}</p>
                                         </div>
                                         </div>
                                         */}


                                        <>
                                            <div className="c-form__row">

                                                <div className="c-form__group c-form__group--small">
                                                    <label
                                                        htmlFor="">{Translate('Huisnr.', props.language)}*</label>
                                                    <Field
                                                        name={'address.house_number'}
                                                        type="number"
                                                        onChange={(e) => {
                                                            const {value} = e.target

                                                            setFieldValue('address.house_number', value)

                                                            if (values.address.zip_code.length === 6 && value.length) {
                                                                setLocationDetailsByZipcode(values.address.zip_code, value, setFieldValue)
                                                            }
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div className="c-form__group c-form__group--small">
                                                    <label
                                                        htmlFor="">{Translate('Toevoeging', props.language)}</label>
                                                    <Field
                                                        name={'address.house_number_addition'}
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="c-form__group c-form__group--small">
                                                    <label
                                                        htmlFor="">{Translate('Postcode', props.language)}*</label>
                                                    <Field
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(' ', '')
                                                            setFieldValue('address.zip_code', e.target.value)
                                                            if (value.length === 6 && values.address.house_number) {
                                                                setLocationDetailsByZipcode(value, values.address.house_number, setFieldValue)
                                                            }

                                                        }}
                                                        name={'address.zip_code'}
                                                        type="text"
                                                        required
                                                    />
                                                </div>

                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('Straat', props.language)}*</label>
                                                    <Field
                                                        name={'address.street'}
                                                        type="text"
                                                        required
                                                    />
                                                </div>

                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('Plaats', props.language)}*</label>
                                                    <Field
                                                        name={'address.city'}
                                                        type="text"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('Land', props.language)}</label>
                                                    <div className="c-form__select">
                                                        <i className="icon icon-arrow-down"/>
                                                        <Field as="select" name="address.country_id">
                                                            <option value={false}
                                                                    disabled={true}>{Translate('Land', props.language)}
                                                            </option>
                                                            {languages.map((language) => (
                                                                <option value={language.id}
                                                                        key={'company-lanuage-' + language.id}>{language.name}</option>
                                                            ))}
                                                        </Field>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className={'c-form__row'}>

                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('Website', props.language)}</label>
                                                    <Field
                                                        name={'company.website'}
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="c-form__group">
                                                    <label
                                                        htmlFor="">{Translate('E-mailadres contactpersoon', props.language)}</label>
                                                    <Field
                                                        name={'contactPerson.email'}
                                                        type="email"
                                                    />
                                                </div>

                                            </div>

                                            <div className={'c-form__row'}>

                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('KvK nr.', props.language)}</label>
                                                    <Field
                                                        name={'company.coc_number'}
                                                        type="number"
                                                    />
                                                </div>

                                                <div className="c-form__group">
                                                    <label htmlFor="">{Translate('BTW nr.', props.language)}</label>
                                                    <Field
                                                        name={'company.vat_number'}
                                                        type="number"
                                                    />
                                                </div>


                                            </div>

                                            <div className={'c-form__row'}>
                                                <div className="c-form__group">
                                                    <label
                                                        htmlFor="">{Translate('Documentnummer', props.language)}*</label>

                                                    <Field
                                                        required
                                                        name={'document_number'}
                                                        type="text"
                                                    />


                                                </div>
                                                <div className={"c-form__group"}>
                                                    <label
                                                        htmlFor="">{Translate('Document type', props.language)}*</label>
                                                    <div className={'c-form__select'}>
                                                        <i className="icon icon-arrow-down"/>
                                                        <Field as="select" name="document_type" required>
                                                            {this.props.webshop.client_id !== 15 && (
                                                                <>
                                                                    <option value={'passport'}>{Translate('Paspoort', this.props.language)}</option>
                                                                    <option value={'identity-card'}>{Translate('Identiteitskaart', this.props.language)}</option>
                                                                </>
                                                            )}
                                                            <option
                                                                value={'drivers-license'}>{Translate('Rijbewijs', props.language)}
                                                            </option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        {!!this.props.webshop.identification_required &&
                                            <>
                                                <div className={'c-form__row'}>
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{Translate('Documentnummer', props.language)}*</label>

                                                        <Field
                                                            required
                                                            name={'document_number'}
                                                            type="text"
                                                        />


                                                    </div>
                                                    <div className={"c-form__group"}>
                                                        <label
                                                            htmlFor="">{Translate('Document type', props.language)}*</label>
                                                        <div className={'c-form__select'}>
                                                            <i className="icon icon-arrow-down"/>
                                                            <Field as="select" name="document_type" required>
                                                                {this.props.webshop.client_id !== 15 && (
                                                                    <>
                                                                        <option value={'passport'}>{Translate('Paspoort', this.props.language)}</option>
                                                                        <option value={'identity-card'}>{Translate('Identiteitskaart', this.props.language)}</option>
                                                                    </>
                                                                )}
                                                                <option
                                                                    value={'drivers-license'}>{Translate('Rijbewijs', props.language)}
                                                                </option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }

                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <button type={'submit'}
                                                style={this.props.webshop.styles.dark}
                                                className="btn btn--rounded btn--primary">


                                            {!this.props.webshop.recurring_enabled &&
                                                <>
                                                    {this.props.webshop.type.code !== 'registration' ?
                                                        <span>{Translate('Ga naar bestellingsoverzicht', props.language)}</span>
                                                        :
                                                        <span>{Translate('Ga naar overzicht', props.language)}</span>
                                                    }
                                                </>
                                            }

                                            {!!this.props.webshop.recurring_enabled &&
                                                <span>{Translate('Ga naar huuroverzicht', this.props.language)}</span>
                                            }

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        )

    }

    _toggleShowMore = () => {
        this.setState({
            showMore: !this.state.showMore
        })
    }
}

export default connect(mapStateToProps)(Form);
